import React from 'react'
import { useTranslation } from 'react-i18next'
import { type UserBasic} from '../../typings'
import Button from '../Button'
import User from '../User'
import './style.sass'

interface UserListProps extends UserBasic {
  secondary?: React.ReactNode
  onClick?: () => void
  onEdit?: () => void
  disabled?: boolean
}

const UserList = ({
  firstName,
  lastName,
  secondary,
  onClick,
  onEdit,
  disabled,
}: UserListProps) => {
  const { t } = useTranslation()
  
  return (
    <div className="user-list-item">
      <User
        firstName={firstName}
        lastName={lastName}
        secondary={secondary}
        onClick={onClick}
        disabled={disabled}
      />
      {onEdit && (
        <Button size="xs" variant="link" onClick={onEdit}>
          {t('manage')}
        </Button>
      )}
    </div>
  )
}

export default UserList
