import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Box from '../components/Box'
import Modal from '../components/Modal'
import UserListItem from '../components/UserListItem'
import FormAccessPeriodEdit, { type FormValues } from '../forms/FormAccessPeriodEdit'
import useAuth from '../utils/useAuth'
import formatName from '../utils/formatName'
import { getSharedThirdParty } from '../api/formatted/vetecard'
import { type UserShared, type VetecardBasic } from '../typings'
import Loader from '../components/Loader'
import { revokeOtherAccess, updateShareOther } from '../api/formatted/share'
import queryClient from '../utils/queryClient'
import formatDate from '../utils/formatDate'
import FileSharingOtherContainer from './FileSharingOtherContainer'

interface VetecardPersonsContainerProps {
  vetecardId: VetecardBasic['id']
}

const VetecardPersonsContainer = ({ vetecardId }: VetecardPersonsContainerProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  // TODO: Provide real owner 
  const [managedUser, setManagedUser] = React.useState<UserShared | undefined>(undefined)
  
  const unsetManagedUser = () => setManagedUser(undefined)

  const { data = [], isFetching, isError } = useQuery({
    queryKey: ['vetecard', vetecardId, 'shared-other'],
    queryFn: () => getSharedThirdParty(currentUser, vetecardId),
  })

  const { mutate: mutateUpdatePeriod, isLoading: mutateUpdatePeriodIsLoading } = useMutation(
    ({ dateFrom, dateTo, timeRangeType }: FormValues) =>
      updateShareOther(currentUser, vetecardId, managedUser, { dateFrom, dateTo, timeRangeType }),
    {
      onSuccess: () => {
        // TODO: Update cache.
        unsetManagedUser()
      },
    }
  )

  const { mutate: mutateRevoke } = useMutation(
    (keycloakId: UserShared['keycloakId']) => revokeOtherAccess(vetecardId, keycloakId),
    {
      onSuccess: (keycloakId: UserShared['keycloakId']) => {
        queryClient.setQueryData(
          ['vetecard', vetecardId, 'shared-other'],
          (oldData?: UserShared[]) =>
            oldData && oldData.filter(item => item.keycloakId !== keycloakId)
        )
        unsetManagedUser()
        toast.success(t('success'))
      },
    }
  )

  const users = useMemo(() => [currentUser as UserShared, ...data], [data])

  const handleUpdatePeriod = (values: FormValues) => mutateUpdatePeriod(values)
  const handleRevoke = () => mutateRevoke(managedUser?.keycloakId || '')

  return (
    <Loader loading={isFetching} error={isError}>
      {users.map(item => {
        const today = new Date(new Date().setHours(0, 0, 0, 1))
        const isExpired = item.dateTo && item.dateTo < today
        const userType = t(item.userType === 'individual' ? 'owner' : `userTypes.${item.userType}`)
        const status = item.dateTo
          ? isExpired
            ? t('hasExpired')
            : item.dateTo
            ? t('daysLeft', { days: formatDate(item.dateTo, 'daysLeft') })
            : t('permantent')
          : ''

        return (
          <UserListItem
            {...item}
            onEdit={() => setManagedUser(item)}
            secondary={`${userType} ${status}`}
            disabled={isExpired}
            onClick={() => navigate(`/user-profile/${item.keycloakId}`)}
          />
        )
      })}
      {managedUser && (
        <Modal
          size="lg"
          title={formatName(managedUser?.firstName, managedUser?.lastName)}
          onClose={() => setManagedUser(undefined)}
          isOpen
        >
          <Box
            title={t('accessManagement.basicInformation')}
            description={t('accessManagement.basicInformationDescr')}
          />
          <Box
            title={t('accessManagement.shareableFiles')}
            description={t('accessManagement.shareableFilesDescr')}
            paddedFooter
            footer={
              <FileSharingOtherContainer
                vetecardId={vetecardId}
                keycloakId={managedUser.keycloakId}
                onSuccess={() => setManagedUser(undefined)}
              />
            }
          />
          <Box
            title={t('accessManagement.accessPeriod')}
            description={t('accessManagement.accessPeriodDescr')}
            paddedFooter
            footer={
              <FormAccessPeriodEdit
                dateFrom={managedUser.dateFrom}
                dateTo={managedUser.dateTo}
                timeRangeType={managedUser.timeRangeType}
                onSubmit={handleUpdatePeriod}
                isLoading={mutateUpdatePeriodIsLoading}
              />
            }
          />
          <Box
            title={t('accessManagement.revokeAccess')}
            description={t('accessManagement.revokeAccessDescr')}
            btnLabel={t('revoke')}
            onClick={handleRevoke}
          />
        </Modal>
      )}
    </Loader>
  )
}

export default VetecardPersonsContainer
