import React from 'react'
import { FormThemeProvider as FormThemeProviderRfc } from 'react-form-component'
import { useTranslation } from 'react-i18next'

export const formTheme = {
  sizes: {
    inputSidePaddings: 16,
    labelGutterBottom: 1,
    inputHeight: 48,
    borderRadius: 8,
    rowGutter: 24,
    inputGutterBottom: 16,
    inlineLabelWidth: 220,
    formActionsDistance: 24,
  },
  colors: {
    accent: '#00605C',
    inputText: '#002C00',
    label: '#979B92',
    inputBorder: '#E2E9DA',
    placeholder: '#979B92',
    fill: '#F5F8F2',
    success: '#008285',
    error: '#F00',
  },
  typography: {
    labelFontSize: 12,
    inputFontSize: 14,
    labelFontWeight: '600',
  },
  toastContainerProps: {
    theme: 'light',
    position: 'bottom-right',
  },
  customValidationFunction: (value: string, type: string): false | string => {
    // Custom validation rules should return false or string with error message.
    switch (type) {
      case 'postcode':
        return value?.length > 15 ? 'Post code too long' : false

      default:
        return false
    }
  },
}

interface FormThemeProviderProps {
  children: React.ReactNode
}

const FormThemeProvider = ({ children }: FormThemeProviderProps) => {
  const { t } = useTranslation()

  const themeWithTranslations = {
    ...formTheme,
    textLabels: {
      formInvalid: t('formTheme.formInvalid'),
      mandatoryField: t('formTheme.mandatoryField'),
      minChars: t('formTheme.minChars'),
      passwordInvalid: t('formTheme.passwordInvalid'),
      emailInvalid: t('formTheme.emailInvalid'),
      urlInvalid: t('formTheme.urlInvalid'),
      phoneInvalid: t('formTheme.phoneInvalid'),
      postCodeInvalid: t('formTheme.postCodeInvalid'),
      fieldgroupInvalid: t('formTheme.fieldgroupInvalid'),
      addMore: t('formTheme.addMore'),
    },
  }

  return <FormThemeProviderRfc theme={themeWithTranslations}>{children}</FormThemeProviderRfc>
}

export default FormThemeProvider
