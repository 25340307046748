import api from '..'
import { type UserBasic } from '../../typings'

export const postPushRegistrations = async (
  { userType, keycloakId }: UserBasic,
  subscribtion: PushSubscriptionJSON,
  remove?: boolean
): Promise<void> => {
  const endpoints =
    userType === 'administrator'
      ? [`0/${userType}/${keycloakId}/vetecard_request/activation/subscription`]
      : [
          `0/${userType}/${keycloakId}/vetecard_request/insertion/subscription`,
          `0/${userType}/${keycloakId}/vetecard/mandatory_vaccination/insertion/subscription`,
          `0/${userType}/${keycloakId}/vetecards/mandatory_vaccinations/valid_until/expiring/subscription`,
          `0/${userType}/${keycloakId}/vetecard/other_vaccination/insertion/subscription`,
          `0/${userType}/${keycloakId}/vetecard/treatment/insertion/subscription`,
          `0/${userType}/${keycloakId}/vetecard/medical_file/insertion/subscription`,
          `0/${userType}/${keycloakId}/vetecard/invoice/insertion/subscription`,
        ]

  await Promise.all(
    endpoints.map(async endpoint => {
      try {
        if (remove) {
          // Use fetch because axios does not support keepalive option which is needed there
          // to prevent requests cancelling after logout redirect.
          await fetch(`${api.defaults.baseURL}${endpoint}/change`, {
            method: 'POST',
            body: JSON.stringify({ old: subscribtion, new: null }),
            headers: {
              Accept: api.defaults.headers.common['Accept'] as string,
              Authorization: api.defaults.headers.common['Authorization'] as string,
            },
            keepalive: true,
          })
        } else {
          await api.post(endpoint, subscribtion)
        }
      } catch (error) {
        // Make sure that subcribtion endpoints does not affect the application.
        return
      }
    })
  )
}
