import api from '..'
import {
  type AgendaPayload,
  type Event,
  type EventPayload,
  type EventType,
  type UserBasic,
  type UserType,
} from '../../typings'
import { getDateDaysTreshold } from '../../utils/helpers'
import { type ListOwnerVetecardsResponseT } from '../pure/owner/vetecard/list'
import { type ListMandatoryVaccinationsApiResponseT } from '../pure/owner/vetecard/mandatory_vaccination/list'
import { type ListOtherVaccinationsApiResponseT } from '../pure/owner/vetecard/other_vaccination/list'
import { type ListTreatmentsApiResponseT } from '../pure/owner/vetecard/treatment/list'
import { type V0FetchVeterinarianProfileResponseT } from '../pure/veterinarian/profile/fetch'
import { type ListVeterinarianVetecardsResponseT } from '../pure/veterinarian/vetecard/list'
import { paths } from '../schema'

const getVetecards = async (userType: string, userId: string) => {
  switch (userType) {
    case 'individual':
    case 'breeder':
    case 'association': {
      const { data } = (await api.get(`/0/${userType}/${userId}/vetecard`)) as {
        data: ListOwnerVetecardsResponseT
      }
      return data
    }
    case 'veterinarian': {
      const { data: profile } = (await api.get(`/0/veterinarian/${userId}/profile`)) as {
        data: V0FetchVeterinarianProfileResponseT
      }
      if (profile === null) {
        throw new Error(`Missing profile for veterinarian ${userId}`)
      }
      const { data } = (await api(`/0/veterinarian/${profile.id}/vetecard`)) as {
        data: ListVeterinarianVetecardsResponseT
      }
      return data
    }
    default:
      throw new Error(`Unhandled userType ${JSON.stringify(userType)}`)
  }
}

export const getEvents = async (userType: UserType, userId: string, payload: AgendaPayload) => {
  const vetecards = await getVetecards(userType, userId)
  let vaccinationsData, treatmentsData

  switch (userType) {
    case 'individual':
    case 'association':
    case 'breeder': {
      // Michal's note: vaccinations and treatments variables represents
      // the simplest possible structure that is sufficient to meet all Agenda view needs.
      // I think having two separate endpoints for these could be a good idea.
      const vetecardVaccinations = await Promise.all(
        vetecards.map(async vetecard => {
          const { data: mandatoryVaccinations } = (await api.get(
            `/0/${userType}/${userId}/vetecard/${vetecard.id}/mandatory_vaccination`
          )) as { data: ListMandatoryVaccinationsApiResponseT }

          const { data: otherVaccinations } = (await api.get(
            `/0/${userType}/${userId}/vetecard/${vetecard.id}/other_vaccination`
          )) as { data: ListOtherVaccinationsApiResponseT }

          return [
            ...mandatoryVaccinations.map(({ type, validFrom, validTo }) => ({
              vetecardId: vetecard.id,
              vetecardName: vetecard.animal.name,
              vetecardSpecies: vetecard.animal.species,
              type,
              validFrom: validFrom ? new Date(validFrom) : null,
              validTo: validTo ? new Date(validTo) : null,
            })),
            ...otherVaccinations.map(({ type, validFrom, validTo }) => ({
              vetecardId: vetecard.id,
              vetecardName: vetecard.animal.name,
              vetecardSpecies: vetecard.animal.species,
              type,
              validFrom: validFrom ? new Date(validFrom) : null,
              validTo: validTo ? new Date(validTo) : null,
            })),
          ]
        })
      )
      vaccinationsData = vetecardVaccinations.flat()

      const vetecardTreatments = await Promise.all(
        vetecards.map(async vetecard => {
          const { data } = (await api.get(
            `/0/owner/${userType}/${userId}/vetecard/${vetecard.id}/treatment`
          )) as { data: ListTreatmentsApiResponseT }

          return data.map(({ storageKey, treatmentDate }) => ({
            vetecardId: vetecard.id,
            vetecardName: vetecard.animal.name,
            vetecardSpecies: vetecard.animal.species,
            storageKey,
            treatmentDate: treatmentDate ? new Date(treatmentDate) : null,
          }))
        })
      )
      treatmentsData = vetecardTreatments.flat()
      break
    }
    default:
      throw new Error(`Unhandled userType ${JSON.stringify(userType)}`)
  }

  const { data: appointmentsData } = (await api.get(`/0/individual/${userId}/calendar_event`)) as {
    data: paths['/0/individual/{individual}/calendar_event']['get']['responses'][200]['content']['application/json']
  }

  // Michal's note: The operations below in my opinin can stay on the frontend side,
  // as this data formatting is purely for special UI needs.
  const vaccineStarts = vaccinationsData.map(item => ({
    vetecardId: item.vetecardId,
    vetecardName: item.vetecardName,
    species: item.vetecardSpecies,
    title: item.type,
    allDay: true,
    start: item.validFrom,
    end: item.validFrom,
    type: 'vaccineStart',
  })) as Event[]

  const today = new Date(new Date().setHours(0, 0, 0, 1))

  const vaccineReminders = vaccinationsData.map(item => {
    const weekBeforeExpiration = item.validTo ? getDateDaysTreshold(item.validTo, -6) : undefined
    const weekAfterExpiration = item.validTo ? getDateDaysTreshold(item.validTo, 7) : undefined
    const isLate = item.validTo && item.validTo < today
    const isSightyLate = item.validTo && weekAfterExpiration && weekAfterExpiration < today
    const isApproaching = weekBeforeExpiration && weekBeforeExpiration < today
    return {
      vetecardId: item.vetecardId,
      vetecardName: item.vetecardName,
      species: item.vetecardSpecies,
      title: item.type,
      allDay: true,
      start: isLate ? item.validTo : weekBeforeExpiration,
      end: isLate && !isSightyLate ? today : item.validTo || undefined,
      type: isLate
        ? 'vaccineReminderExpired'
        : isApproaching
        ? 'vaccineReminderApproaching'
        : 'vaccineReminder',
    }
  }) as Event[]

  const treatments = treatmentsData.map(item => ({
    vetecardId: item.vetecardId,
    vetecardName: item.vetecardName,
    species: item.vetecardSpecies,
    title: item.storageKey,
    allDay: true,
    start: item.treatmentDate || undefined,
    end: item.treatmentDate || undefined,
    type: 'treatment',
  })) as Event[]

  const appointments = appointmentsData.map(item => ({
    id: item.id,
    title: item.title,
    description: item.description,
    type: item.type,
    start: new Date(item.date),
    end: new Date(item.date),
    ...(item.vetecard?.id ? { vetecardId: item.vetecard.id } : {}),
  })) as Event[]

  const ret = [...vaccineStarts, ...vaccineReminders, ...treatments, ...appointments]
    .filter(({ species }) => (!payload.species ? true : species === payload.species))
    .filter(({ vetecardId }) => (!payload.vetecardId ? true : vetecardId === payload.vetecardId))
  return ret
}

export const addEvent = async (
  { keycloakId }: UserBasic,
  { title, date: dateString, description, type, vetecardId }: EventPayload
): Promise<Event> => {
  const date = new Date(dateString)
  const response = await api.post(`/0/individual/${keycloakId}/calendar_events/appointment`, {
    title,
    date,
    description: description || null,
    type,
    veterinarian: null, // Why it is required by API?
    vetecard: vetecardId ? { id: parseInt(vetecardId) } : null,
  })
  const { id } =
    response.data as paths['/0/individual/{individual}/calendar_events/appointment']['post']['responses'][200]['content']['application/json']

  return {
    id,
    title,
    description,
    type: type as EventType,
    start: date,
    end: date,
    vetecardId: vetecardId ? parseInt(vetecardId) : undefined,
  }
}

export const editEvent = async (
  { keycloakId }: UserBasic,
  { id, title, date: dateString, description, type, vetecardId }: EventPayload
): Promise<Event> => {
  const date = new Date(dateString)
  await api.patch(`/0/individual/${keycloakId}/calendar_events/appointment/${id}`, {
    title,
    date,
    description: description || null,
    veterinarian: null, // Why it is required by API?
    vetecard: vetecardId ? { id: parseInt(vetecardId) } : null,
  })
  return {
    id,
    title,
    description,
    type: type as EventType,
    start: date,
    end: date,
    vetecardId: vetecardId ? parseInt(vetecardId) : undefined,
  }
}
