import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import Form, { FormActions, Input, Row, Select, type value } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { searchAnimalsDb } from '../api/formatted/search'
import { requestVetecard } from '../api/formatted/vetecardRequests'
import Alert from '../components/Alert'
import Block from '../components/Block'
import Box from '../components/Box'
import Button from '../components/Button'
import Panel from '../components/Panel'
import SearchResult from '../components/SearchResult'
import SubmitButton from '../components/SubmitButton'
import Text from '../components/Text'
import IndividualSearchInput from '../containers/IndividualSearchInput'
import VeterinarianSearchInput from '../containers/VeterinarianSearchInput'
import {
  type AnimalSearchPayload,
  type AnimalSearchResult,
  type RequestVetecardPayload,
  type Species,
  type VetecardBasic,
} from '../typings'
import formatDate from '../utils/formatDate'
import { useTranslatedOptions } from '../utils/helpers'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

const FormRequestVetecard = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [step, setStep] = React.useState('species')
  const [animalSearchCriteria, setAnimalSearchCriteria] =
    React.useState<AnimalSearchPayload | null>(null)
  const [species, setSpecies] = React.useState<Species | null>(null)
  const [animalData, setAnimalData] = React.useState<Partial<RequestVetecardPayload> | null>(null)
  const [disabledFields, setDisabledFields] = React.useState<string[]>([])
  const [addNext, setAddNext] = React.useState(false)
  const [resetKey, setResetKey] = React.useState(0)

  const speciesOptions = useTranslatedOptions('species')
  const identifierTypeOptions = useTranslatedOptions('identifierType').reverse()
  const identifierLocationOptions = useTranslatedOptions('identifierLocation')
  const breedOptions = useTranslatedOptions(species || '', 'breeds').sort((a, b) => {
    if (a.label < b.label) return -1
    if (a.label > b.label) return 1
    return 0
  })
  const sexAllOptions = useTranslatedOptions('sex')
  const sexOptions = React.useMemo(
    () =>
      species === 'horse' ? sexAllOptions : sexAllOptions.filter(item => item.value !== 'gelding'),
    [species]
  )

  const coatingColourOptions = useTranslatedOptions(species || '', 'coatingColors')

  const { currentUser, hasPermission } = useAuth()

  const isVeterinarian = currentUser.userType === 'veterinarian'
  const isIdentifierNone = animalData?.identifierType === 'none'

  React.useEffect(() => {
    if (species) {
      // If user changes species, assume that whole existing form data is outdated.
      setAnimalData(null)
      setDisabledFields([])
    }
  }, [species])

  const { isLoading, mutate } = useMutation(
    (payload: RequestVetecardPayload) => requestVetecard(currentUser, payload),
    {
      onSuccess: (vetecard: VetecardBasic) => {
        queryClient.setQueryData(['animal-search'], [])
        queryClient.setQueryData(['individuals-search'], [])
        queryClient.setQueryData(['veterinaries-search'], [])
        queryClient.setQueryData(
          ['vetecard-list'],
          (oldData?: { vetecards: VetecardBasic[]; hasMore: boolean }) =>
            oldData && {
              vetecards: [...oldData.vetecards, vetecard],
              hasMore: oldData.hasMore,
            }
        )
        if (addNext) {
          resetForm()
        } else {
          navigate(hasPermission('vetecards-advanced') ? '/vetecard-requests' : '/vetecards')
        }
        toast.success(t('vetecardRequestCreated'))
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 409) {
          toast.error(t('requestVetecardError409'))
          setStep('409')
        } else {
          toast.error(t('requestVetecardError'))
        }
      },
    }
  )

  const {
    data: searchResults,
    isFetching: searchResultsIsFetching,
    refetch: searchResultsRefetch,
  } = useQuery({
    queryKey: ['animal-search'],
    queryFn: () => searchAnimalsDb(animalSearchCriteria || { name: '' }),
    enabled: false,
    onSuccess: () => setStep('searchResult'),
    onError: () => toast.error(t('couldNotConnectToHorses')),
  })

  React.useEffect(() => {
    if (animalSearchCriteria) searchResultsRefetch()
  }, [animalSearchCriteria])

  const handleSelectResult = (item: AnimalSearchResult) => {
    const { name, birthDate, identifier, sex, breed, coatingColour } = item
    setAnimalData({
      name,
      sex,
      breed,
      coatingColour,
      birthDate,
      identifierType: identifier.type,
      identifierNumber: identifier.number,
      identifierDate: identifier.date,
      identifierLocation: identifier.location || 'leftNeckline',
    })
    setDisabledFields([
      ...(name ? ['name'] : []),
      ...(sex ? ['sex'] : []),
      ...(breed ? ['breed'] : []),
      ...(coatingColour ? ['coatingColour'] : []),
      ...(birthDate ? ['birthDate'] : []),
      ...(identifier.type ? ['identifierType'] : []),
      ...(identifier.number ? ['identifierNumber'] : []),
      ...(identifier.date ? ['identifierDate'] : []),
      ...(identifier.location ? ['identifierLocation'] : []),
    ])
    setStep('form')
  }

  const handleSubmit = (next: boolean, associationId?: string, documentDate?: string) => {
    console.log('handleSubmit documentDate: ', documentDate)
    setAddNext(next)
    const payload = {
      ...animalData,
      species,
      ...(isVeterinarian
        ? {
            keycloakId: associationId,
            documentDate: documentDate,
            veterinarianId: associationId,
          }
        : {
            veterinarianId: associationId,
          }),
    } as RequestVetecardPayload
    mutate(payload)
  }

  const resetForm = () => {
    setSpecies(null)
    setStep('species')
    setResetKey(resetKey + 1)
    window.scrollTo(0, 0)
  }

  const showBreedOptions = species === 'dog' || species == 'cat'

  return (
    <React.Fragment key={resetKey}>
      {step === 'species' && (
        <Panel title={t('speciesLabel')}>
          <Form fields={['species']} allMandatory>
            <Select
              name="species"
              label={t('selectAnimalSpecies')}
              options={speciesOptions}
              onChange={(value: value) => setSpecies(value)}
              initialValue={species}
            />
            <FormActions>
              <Button component={Link} variant="ghost" to="/vetecards">
                {t('goBack')}
              </Button>
              <Button
                onClick={() => setStep(species === 'horse' ? 'methodSelector' : 'form')}
                disabled={!species}
                data-cy="continue"
              >
                {t('continue')}
              </Button>
            </FormActions>
          </Form>
        </Panel>
      )}
      {step === 'methodSelector' && (
        <Panel title={t('wayOfProviding')}>
          <Button block onClick={() => setStep('searchByAttributes')}>
            {t('searchHorsesByInformation')}
          </Button>
          <Button block onClick={() => setStep('searchById')}>
            {t('findAHorseById')}
          </Button>
          <Button
            block
            onClick={() => {
              setAnimalData(species === 'horse' ? { identifierLocation: 'leftNeckline' } : null)
              setDisabledFields([])
              setStep('form')
            }}
          >
            {t('fillTheDataManually')}
          </Button>
          <Button variant="ghost" block onClick={() => setStep('species')}>
            {t('back')}
          </Button>
        </Panel>
      )}
      {step === 'searchByAttributes' && (
        <Panel title={t('searchHorsesByInformation')}>
          <Form fields={['name', 'minBirthYear', 'maxBirthYear', 'sexCode']} mandatory={['name']}>
            <Input name="name" label={t('name')} />
            <Row>
              <Input
                name="minBirthYear"
                label={t('yearOfBirth.min')}
                type="number"
                placeholder="RRRR"
                // min="1970"
                max={new Date().getFullYear()}
              />
              <Input
                name="maxBirthYear"
                label={t('yearOfBirth.max')}
                type="number"
                placeholder="RRRR"
                // min="1970"
                max={new Date().getFullYear()}
              />
            </Row>
            <Select name="sexCode" label={t('sexLabel')} options={sexOptions} />
            <FormActions>
              <Button variant="ghost" onClick={() => setStep('methodSelector')}>
                {t('back')}
              </Button>
              <SubmitButton
                onClick={(values: AnimalSearchPayload) => setAnimalSearchCriteria(values)}
                loading={searchResultsIsFetching}
              >
                {t('search')}
              </SubmitButton>
            </FormActions>
          </Form>
        </Panel>
      )}
      {step === 'searchById' && (
        <Panel title={t('findAHorseById')}>
          <Form fields={['id']} allMandatory>
            <Input name="id" label={t('searchByIdentifier')} />
            <FormActions>
              <Button variant="ghost" onClick={() => setStep('methodSelector')}>
                {t('back')}
              </Button>
              <SubmitButton
                onClick={(values: AnimalSearchPayload) => setAnimalSearchCriteria(values)}
                loading={searchResultsIsFetching}
              >
                {t('search')}
              </SubmitButton>
            </FormActions>
          </Form>
        </Panel>
      )}
      {step === 'searchResult' && (
        <Block title="Search result">
          {searchResults?.length ? (
            <>
              <Text>{t('selectHorseFromResults')}:</Text>
              {searchResults.map((item, index: number) => (
                <SearchResult key={index} onSelect={() => handleSelectResult(item)} {...item} />
              ))}
            </>
          ) : (
            <Alert type="info" primary={t('noResultsTryRefine')} />
          )}
          <FormActions>
            <Button variant="ghost" onClick={() => setStep('methodSelector')}>
              {t('back')}
            </Button>
            <Button onClick={() => setStep('form')}>{t('enterDataManually')}</Button>
          </FormActions>
        </Block>
      )}
      {step === 'form' && (
        <Panel title="Animal details">
          <Form
            fields={[
              'name',
              'birthDate',
              'identifierType',
              ...(isIdentifierNone
                ? []
                : ['identifierNumber', 'identifierDate', 'identifierLocation']),
            ]}
            mandatory={[
              'name',
              'birthDate',
              'identifierType',
              ...(isIdentifierNone ? [] : ['identifierLocation']),
            ]}
          >
            <Block condensed title={t('primaryInfo')}>
              <Input
                name="name"
                label={t('name')}
                initialValue={animalData?.name}
                disabled={disabledFields.includes('name')}
              />
              <Input
                name="birthDate"
                type="date"
                label={t('birthDate')}
                initialValue={animalData?.birthDate && formatDate(animalData.birthDate, 'input')}
                disabled={disabledFields.includes('birthDate')}
              />
            </Block>
            <Block condensed title={t('description')}>
              <Select
                name="sex"
                label={t('sexLabel')}
                options={sexOptions}
                placeholder={t('notSpecified')}
                initialValue={animalData?.sex}
              />
              {showBreedOptions ? (
                <Select
                  name="breedCode"
                  label={t('breedCode')}
                  options={breedOptions}
                  placeholder={t('notSpecified')}
                  initialValue={animalData?.breed}
                />
              ) : (
                <Input name="breedCode" label={t('breedCode')} initialValue={animalData?.breed} />
              )}
              {showBreedOptions ? (
                <Select
                  name="coatingColour"
                  label={t('colour')}
                  options={coatingColourOptions}
                  placeholder={t('notSpecified')}
                  initialValue={animalData?.coatingColour}
                />
              ) : (
                <Input
                  name="coatingColour"
                  label={t('colour')}
                  initialValue={animalData?.coatingColour}
                />
              )}
            </Block>
            <Block condensed title={t('marking')}>
              <Select
                name="identifierType"
                label={t('identifierTypeLabel')}
                options={identifierTypeOptions}
                initialValue={animalData?.identifierType}
                disabled={disabledFields.includes('identifierType')}
                onChange={(identifierType: RequestVetecardPayload['identifierType']) => {
                  setAnimalData(prevValue => ({
                    ...prevValue,
                    identifierType,
                    ...(identifierType === 'transponder'
                      ? { identifierLocation: 'leftJugularGutter' }
                      : {}),
                  }))
                }}
              />
              {!isIdentifierNone && (
                <>
                  <Input
                    name="identifierNumber"
                    label={`${t('identifier')} ${t('number')}`}
                    initialValue={animalData?.identifierNumber}
                    disabled={disabledFields.includes('identifierNumber')}
                  />
                  <Input
                    name="identifierDate"
                    type="date"
                    label={t('implementationDate')}
                    initialValue={
                      animalData?.identifierDate && formatDate(animalData.identifierDate, 'input')
                    }
                    disabled={disabledFields.includes('identifierDate')}
                  />
                  <Select
                    name="identifierLocation"
                    label={t('identifierLocationLabel')}
                    options={identifierLocationOptions}
                    initialValue={animalData?.identifierLocation}
                    disabled={disabledFields.includes('identifierLocation')}
                  />
                </>
              )}
            </Block>
            <FormActions>
              <Button variant="ghost" onClick={() => setStep('species')}>
                {t('back')}
              </Button>
              <SubmitButton
                onClick={({ birthDate, identifierDate, ...rest }: RequestVetecardPayload) => {
                  setAnimalData({
                    ...rest,
                    birthDate: new Date(birthDate),
                    ...(identifierDate ? { identifierDate: new Date(identifierDate) } : {}),
                  })
                  setStep('association')
                }}
                data-cy="continue"
              >
                {t('continue')}
              </SubmitButton>
            </FormActions>
          </Form>
        </Panel>
      )}
      {step === 'association' && (
        <Panel title={isVeterinarian ? 'Animal owner' : 'Refer the Veterinarian'}>
          <Form
            fields={['associationId', ...(isVeterinarian ? ['documentDate'] : [])]}
            allMandatory={isVeterinarian}
          >
            {isVeterinarian ? (
              <>
                <IndividualSearchInput name="associationId" label={t('owner')} />
                <Box
                  title={t('consentGneration.title')}
                  description={t('consentGneration.description')}
                  footer={
                    <Input
                      name="documentDate"
                      type="date"
                      label={t('documentDate')}
                      initialValue={formatDate(new Date(), 'input')}
                      noBottomGutter
                    />
                  }
                  paddedFooter
                />
                <br />
              </>
            ) : (
              <VeterinarianSearchInput
                name="associationId"
                label={t('searchYourVeterinarian')}
                help={t('youCanAlsoLater')}
              />
            )}
            <FormActions>
              <Button onClick={() => setStep('form')} variant="ghost">
                {t('back')}
              </Button>
              <SubmitButton
                onClick={({
                  associationId,
                  documentDate,
                }: {
                  associationId?: string
                  documentDate?: string
                }) => handleSubmit(true, associationId, documentDate)}
                loading={isLoading}
                variant="ghost"
              >
                {t('createAndAddNext')}
              </SubmitButton>
              <SubmitButton
                onClick={({
                  associationId,
                  documentDate,
                }: {
                  associationId?: string
                  documentDate?: string
                }) => handleSubmit(false, associationId, documentDate)}
                loading={isLoading}
                data-cy="create"
              >
                {t('create')}
              </SubmitButton>
            </FormActions>
          </Form>
        </Panel>
      )}
      {step === '409' && (
        <Panel title={t('requestVetecardError409')}>
          <Text>
            {t('requestVetecardError409Descr', { identifier: animalData?.identifierNumber })}
          </Text>
          <FormActions>
            <Button onClick={() => setStep('form')}>{t('back')}</Button>
          </FormActions>
        </Panel>
      )}
    </React.Fragment>
  )
}

export default FormRequestVetecard
