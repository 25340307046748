import React from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation, useQuery } from '@tanstack/react-query'
import Form, { FormActions, Input, Select, TextArea } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { addEvent, editEvent } from '../api/formatted/agenda'
import { getVetecards } from '../api/formatted/vetecard'
import SubmitButton from '../components/SubmitButton'
import { type Event, type EventPayload } from '../typings'
import formatDate from '../utils/formatDate'
import { useTranslatedOptions } from '../utils/helpers'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

interface FormEventProps {
  initialValues?: Partial<Event>
  onSuccess: () => void
}

const FormEvent = ({ initialValues, onSuccess }: FormEventProps) => {
  const { t } = useTranslation()
  const typeOptions = useTranslatedOptions('selectableEventTypes')
  const { currentUser } = useAuth()
  const isEdit = initialValues?.id

  const { data: { vetecards } = { vetecards: [] }, isFetching } = useQuery(
    ['agenda-vetecards'],
    () => getVetecards(currentUser),
    {
      enabled: false, // Only read from cache
    }
  )

  const { isLoading: addIsLoading, mutate: addMutate } = useMutation(
    (payload: EventPayload) => addEvent(currentUser, payload),
    {
      onSuccess: (event: Event) => {
        queryClient.setQueryData(['events'], (oldData: Event[] = []) => [event, ...oldData])
        toast.success(t('success'))
        onSuccess && onSuccess()
      },
    }
  )

  const { isLoading: editIsLoading, mutate: editMutate } = useMutation(
    (payload: EventPayload) => editEvent(currentUser, { id: initialValues?.id, ...payload }),
    {
      onSuccess: (event: Event) => {
        queryClient.setQueryData(['events'], (oldData: Event[] = []) =>
          oldData.map(item => (item.id === event.id ? event : item))
        )
        toast.success(t('success'))
        onSuccess && onSuccess()
      },
    }
  )

  const handleSubmit = (values: EventPayload) => {
    if (isEdit) {
      editMutate(values)
    } else {
      addMutate(values)
    }
  }

  return (
    <Form
      fields={['title', 'date', 'description', 'type', 'vetecardId']}
      mandatory={['title', 'date', 'type']}
    >
      <Input name="title" label={t('title')} initialValue={initialValues?.title} />
      <Input
        name="date"
        label={t('date')}
        type="date"
        initialValue={initialValues?.start && formatDate(initialValues.start, 'input')}
      />
      <TextArea
        name="description"
        rows={3}
        label={t('description')}
        initialValue={initialValues?.description}
      />
      <Select
        name="type"
        label={t('type')}
        options={typeOptions}
        initialValue={initialValues?.type || 'appointment'}
      />
      <Select
        name="vetecardId"
        label={t('vetecard')}
        options={vetecards.map(item => ({ label: item.name, value: item.id }))}
        suffix={isFetching ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : undefined}
        initialValue={initialValues?.vetecardId}
      />
      <FormActions>
        <SubmitButton onClick={handleSubmit} loading={addIsLoading || editIsLoading}>
          {t('create')}
        </SubmitButton>
      </FormActions>
    </Form>
  )
}

export default FormEvent
