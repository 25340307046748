import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { revokeVetAccess } from '../api/formatted/share'
import { archiveMainVeterinarian, getMainVeterinarians } from '../api/formatted/vetecard'
import { getSharedVeterinarians } from '../api/formatted/vetecard'
import AddButton from '../components/AddButton'
import Box from '../components/Box'
import Loader from '../components/Loader'
import Modal, { type ModalRef } from '../components/Modal'
import VeterinarianCard from '../components/VeterinarianCard'
import FormAssignVeterinarian from '../forms/FormAssignVeterinarian'
import { type VetecardBasic, type VeterinarianBasic, type VeterinarianShared } from '../typings'
import formatDate from '../utils/formatDate'
import formatName from '../utils/formatName'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'
import FileSharingVetContainer from './FileSharingVetContainer'

interface VetecardVetsContainerProps {
  vetecardId: VetecardBasic['id']
}

const VetecardVetsContainer = ({ vetecardId }: VetecardVetsContainerProps) => {
  const modalRef = React.useRef<ModalRef>(null)
  const { t } = useTranslation()
  const { hasPermission, currentUser } = useAuth()
  const canManageMainVeterinarians = hasPermission('vetecards-manage_main_veterinarian')
  const [managedVet, setManagedVet] = React.useState<VeterinarianShared | undefined>(undefined)
  const unsetManagedVet = () => setManagedVet(undefined)

  const {
    data: main = [],
    isFetching: mainIsFetching,
    isError: mainIsError,
  } = useQuery({
    queryKey: ['vetecard', vetecardId, 'referring-vets'],
    queryFn: () => getMainVeterinarians(vetecardId),
  })

  const {
    data: shared = [],
    isFetching: sharedIsFetching,
    isError: sharedIsError,
  } = useQuery({
    queryKey: ['vetecard', vetecardId, 'shared-vets'],
    queryFn: () => getSharedVeterinarians(currentUser, vetecardId),
  })

  const { mutate: archiveMainMutate } = useMutation(
    (veterinarianId: VeterinarianBasic['veterinarianId']) =>
      archiveMainVeterinarian(veterinarianId, vetecardId),
    {
      onSuccess: (veterinarianId: VeterinarianBasic['veterinarianId']) => {
        queryClient.setQueryData(
          ['vetecard', vetecardId, 'referring-vets'],
          (oldData?: VeterinarianBasic[]) =>
            oldData && oldData.filter(item => item.veterinarianId !== veterinarianId)
        )
        toast.success(t('mainVeterinarianArchived'))
      },
    }
  )

  const { mutate: revokeSharedMutate } = useMutation(
    (veterinarianId: VeterinarianBasic['veterinarianId']) =>
      revokeVetAccess(vetecardId, veterinarianId),
    {
      onSuccess: (veterinarianId: VeterinarianBasic['veterinarianId']) => {
        queryClient.setQueryData(
          ['vetecard', vetecardId, 'shared-vets'],
          (oldData?: VeterinarianBasic[]) =>
            oldData && oldData.filter(item => item.veterinarianId !== veterinarianId)
        )
        unsetManagedVet()
        toast.success(t('success'))
      },
    }
  )

  return (
    <Loader loading={mainIsFetching || sharedIsFetching} error={mainIsError || sharedIsError}>
      {main.map(item => (
        <VeterinarianCard
          key={item.veterinarianId}
          {...item}
          secondary={t('main')}
          onDelete={
            canManageMainVeterinarians
              ? () => archiveMainMutate(item.veterinarianId || 0)
              : undefined
          }
        />
      ))}
      {shared.map(item => {
        const today = new Date(new Date().setHours(0, 0, 0, 1))
        const isExpired = item.dateTo && item.dateTo < today
        return (
          <VeterinarianCard
            key={item.veterinarianId}
            {...item}
            secondary={`${t('temporary')} (${
              item.dateTo
                ? isExpired
                  ? t('hasExpired')
                  : item.dateTo
                  ? t('daysLeft', { days: formatDate(item.dateTo, 'daysLeft') })
                  : t('permantent')
                : ''
            })`}
            onEdit={canManageMainVeterinarians ? () => setManagedVet(item) : undefined}
          />
        )
      })}
      {/* TODO:  FormShareWithVeterinarian from SharedWithVetContainer */}
      {canManageMainVeterinarians ? (
        <>
          <AddButton variant="ghost" size="xs" padded onClick={() => modalRef.current?.open()}>
            {t('addVeterinarian')}
          </AddButton>
          <Modal title={t('addMainVeterinarian')} ref={modalRef}>
            <FormAssignVeterinarian
              vetecardId={vetecardId}
              onSuccess={() => modalRef.current?.close()}
            />
          </Modal>
        </>
      ) : (
        <></>
      )}
      {managedVet && (
        <Modal
          size="lg"
          title={formatName(managedVet?.firstName, managedVet?.lastName)}
          onClose={unsetManagedVet}
          isOpen
        >
          <Box
            title={t('accessManagement.basicInformation')}
            description={t('accessManagement.basicInformationDescr')}
          />
          <Box
            title={t('accessManagement.shareableFiles')}
            description={t('accessManagement.shareableFilesDescr')}
            paddedFooter
            footer={
              <FileSharingVetContainer
                vetecardId={vetecardId}
                veterinarianId={managedVet.veterinarianId || 0}
                onSuccess={() => setManagedVet(undefined)}
              />
            }
          />
          <Box
            title={t('accessManagement.revokeAccess')}
            description={t('accessManagement.revokeAccessDescr')}
            btnLabel={t('revoke')}
            onClick={() => revokeSharedMutate(managedVet.veterinarianId || 0)}
          />
        </Modal>
      )}
    </Loader>
  )
}

export default VetecardVetsContainer
