import React from 'react'
import { faLocationPin, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { type VeterinarianBasic } from '../../typings'
import formatName from '../../utils/formatName'
import ConfirmDelete from '../ConfirmDelete'
import Count from '../Count'
import IconButton from '../IconButton'
import Picture from '../Picture'
import './style.sass'

interface VeterinarianCardProps extends VeterinarianBasic {
  secondary?: string
  onEdit?: () => void
  onDelete?: () => void
}

const VeterinarianCard = ({
  veterinarianId,
  firstName,
  lastName,
  institutions = [],
  secondary,
  onEdit,
  onDelete,
}: VeterinarianCardProps) => {
  const name = formatName(firstName, lastName)
  const moreInstitutions =
    institutions.length > 1 ? (
      <>
        {' '}
        <Count plus number={institutions.length - 1} />
      </>
    ) : null
  return (
    <Link className="veterinarian-card" to={`/veterinaries/${veterinarianId}`}>
      <Picture small className="veterinarian-card__graphic" species="vet" square />
      <div className="veterinarian-card__info">
        <div className="veterinarian-card__name">
          {name} {secondary && <div className="veterinarian-card__secondary">{secondary}</div>}
        </div>
        {institutions[0] ? (
          <div className="veterinarian-card__footer">
            <div className="veterinarian-card__institution">
              {institutions[0].name}
              {moreInstitutions}
            </div>
            <div className="veterinarian-card__contact">
              <div>
                <FontAwesomeIcon icon={faLocationPin} className="veterinarian-card__icon" />{' '}
                {institutions[0].contact?.city}
                {moreInstitutions}
              </div>
            </div>
          </div>
        ) : null}
        <div className="veterinarian-card__actions">
          {onEdit && (
            <IconButton onClick={onEdit}>
              <FontAwesomeIcon icon={faPenToSquare} className="veterinarian-card__edit" />
            </IconButton>
          )}
          {onDelete && (
            <ConfirmDelete name={name} onDelete={onDelete} className="veterinarian-card__delete" />
          )}
        </div>
      </div>
    </Link>
  )
}

export default VeterinarianCard
